import { TimelineSectionType } from '@motional-cc/fe/interface/api/api-concierge';
import { VehicleConfig } from '@motional-cc/fe/interface/api/registrar';
import t from '@motional-cc/fe/tools/translate';
import difference from 'lodash/fp/difference';
import xor from 'lodash/fp/xor';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import upperFirst from 'lodash/upperFirst';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { userApi } from 'src/api/user';
import { oktaLogout } from 'src/auth/okta';
import LinkButton from 'src/components/common/LinkButton';
import { DispatchBaseType } from 'src/components/Dispatch/DispatchRequestForm';
import { JobColumnKey } from 'src/components/FleetManagement/Jobs/JobColumnPicker';
import { VehicleRegistrationColumnKey } from 'src/components/FleetManagement/Jobs/VehicleRegistrationColumnPicker';
import { useMessages } from 'src/components/Messages/messages-context';
import { UserColumnKey } from 'src/components/Users/UserColumnPicker';
import { FleetFilter } from 'src/interface/armada';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import useDebouncedValue from 'src/tools/hooks/useDebouncedValue';
import { MapLayer } from 'src/tools/hooks/useHasMapLayer';
import { ToggleName } from 'src/tools/hooks/useHasToggle';
import usePrevious from 'src/tools/hooks/usePrevious';

const STORAGE_NAME = 'useUserSetting';

type TabliseSettings<T, Suffix extends string> = {
  [Key in keyof T as Key extends string ? `${Key}${Suffix}` : never]: T[Key][];
};

type TableSetting<Settings extends object> = TabliseSettings<
  Settings,
  '-table-columns'
> &
  TabliseSettings<Settings, '-table-column-order'>;

export type TableSettings = {
  job: JobColumnKey;
  user: UserColumnKey;
  'vehicle-registration': VehicleRegistrationColumnKey;
  'vehicle-config':
    | keyof Omit<VehicleConfig, 'components'>
    | `components.${string}`;
};
export type TableSettingName = keyof TableSettings;

type SettingTypes = {
  toggles: ToggleName[];
  'pinned-roles': string[];
  'pinned-behaviors': string[];
  'vehicle-history-filter': TimelineSectionType[];
  'additional-fleet-filters': FleetFilter[];
  'map-layers': MapLayer[];
  'prefer-compact': boolean;
  'drawer-height--vehicle-info': number;
  'previous-route-dispatch': [string, string, string];
  'map-service-access-token': string;
  'dispatch-type': [DispatchBaseType, ...(string | undefined)[]] | [];
} & TableSetting<TableSettings>;

export type ProfileSettingName = keyof SettingTypes;
type ProfileSettings = Partial<SettingTypes>;

type UpdateFunction<SettingName extends ProfileSettingName> = (
  previousValue: ProfileSettings[SettingName],
) => SettingTypes[SettingName] | undefined;

type SettingSetter = <SettingName extends ProfileSettingName>(
  settingName: SettingName,
  updateFunction: UpdateFunction<SettingName>,
) => void;

interface ProfileSettingsState {
  settings: ProfileSettings;
  setSetting: SettingSetter;
  error?: ApiError | null;
  isSaving: boolean;
}

const ProfileSettingsContext = createContext<ProfileSettingsState>({
  settings: {},
  setSetting: noop,
  isSaving: false,
});

const initialSettings: ProfileSettings = JSON.parse(
  localStorage.getItem(STORAGE_NAME) ?? '{}',
);

type Props = {
  children: ReactNode;
};

export function ProfileSettingsProvider({ children }: Props) {
  const { userProfile } = userApi.useUserProfile();
  const previousScopes = usePrevious(userProfile?.scopes);
  const {
    mutate: persistCachedChanges,
    isLoading,
    error,
  } = userApi.useMutateUserProfile({
    successStyle: 'succeed-silently',
  });
  const [isUsingPersistedSettings, setIsUsingPersistedSettings] =
    useState(false);
  const [cachedSettings, setCachedSettings] =
    useState<ProfileSettings>(initialSettings);
  const debouncedCachedSettings = useDebouncedValue(
    cachedSettings,
    inMilliseconds(5, 'seconds'),
  );

  const { showMessage } = useMessages();

  const setSetting = useCallback<SettingSetter>(
    (settingName, updateFunction) => {
      setCachedSettings((cachedSettings) => {
        const currentSettingValue = cachedSettings[settingName];
        const updatedSetting = updateFunction(currentSettingValue);
        const updatedSettings = {
          ...cachedSettings,
        };
        if (updatedSetting === undefined) {
          delete updatedSettings[settingName];
        } else {
          updatedSettings[settingName] = updatedSetting;
        }
        localStorage.setItem(STORAGE_NAME, JSON.stringify(updatedSettings));
        return updatedSettings;
      });
    },
    [],
  );

  useEffect(
    function notifyIfScopesChange() {
      const currentScopes = userProfile?.scopes ?? [];
      if (!previousScopes || xor(previousScopes, currentScopes).length === 0)
        return;

      const addedScopes = difference(currentScopes ?? [], previousScopes);
      const removedScopes = difference(previousScopes, currentScopes ?? []);

      showMessage({
        type: 'warning',
        title: 'Your scopes have been updated',
        description: (
          <>
            <p>Your scopes have been updated</p>

            {addedScopes.length > 0 && (
              <p>
                <b>New scopes:</b>{' '}
                {addedScopes
                  .map((scope) => t(`permissions.scopeName.${scope}`))
                  .join(', ')}
              </p>
            )}

            {removedScopes.length > 0 && (
              <p>
                <b>Removed scopes:</b>{' '}
                {removedScopes
                  .map((scope) => t(`permissions.scopeName.${scope}`))
                  .join(', ')}
              </p>
            )}

            <p>
              This may require you to{' '}
              <LinkButton onClick={oktaLogout}>log out</LinkButton> and in again
              to take full effect.
            </p>
          </>
        ),
      });
    },
    [previousScopes, userProfile, showMessage],
  );

  useEffect(
    function initiallyUpdateFromPersistedSettings() {
      if (!isUsingPersistedSettings && userProfile) {
        const newSettings = userProfile.settings || {};
        setIsUsingPersistedSettings(true);
        setCachedSettings(newSettings);
        localStorage.setItem(STORAGE_NAME, JSON.stringify(newSettings));
      }
    },
    [isUsingPersistedSettings, userProfile, cachedSettings],
  );

  useEffect(
    function updatePersistedSettings() {
      if (
        !userProfile ||
        cachedSettings !== debouncedCachedSettings ||
        isEqual(userProfile.settings || {}, debouncedCachedSettings)
      ) {
        return;
      }

      persistCachedChanges({
        settings: debouncedCachedSettings,
      });
    },
    [
      cachedSettings,
      debouncedCachedSettings,
      persistCachedChanges,
      userProfile,
    ],
  );

  useEffect(
    function showErrors() {
      if (!error) return;

      showMessage({
        title: 'Saving user settings failed',
        description: error.message,
        type: 'error',
        overridable: true,
      });
    },
    [showMessage, error],
  );

  return (
    <ProfileSettingsContext.Provider
      value={{
        settings: cachedSettings,
        setSetting,
        isSaving: isLoading,
        error,
      }}
    >
      {children}
    </ProfileSettingsContext.Provider>
  );
}

export function useUserProfileSetting<SettingName extends ProfileSettingName>(
  settingName: SettingName,
) {
  type Value = SettingTypes[SettingName];
  type ValueSetter = (updateFunction: UpdateFunction<SettingName>) => void;
  type SetterKey = `set${Capitalize<SettingName>}`;

  const { settings, setSetting, error, isSaving } = useContext(
    ProfileSettingsContext,
  );

  const setThisSetting = useCallback<ValueSetter>(
    (updateFunction) => setSetting(settingName, updateFunction),
    [setSetting, settingName],
  );

  const returnObject = useMemo(
    () => ({
      setting: settings[settingName],
      setSetting: setThisSetting,
      [settingName as SettingName]: settings[settingName],
      [`set${upperFirst(settingName)}` as SetterKey]: setThisSetting,
      error,
      isSaving,
    }),
    [setThisSetting, settingName, settings, error, isSaving],
  );

  return returnObject as Readonly<
    {
      [key in 'setting' | SettingName]: Value | undefined;
    } & {
      [key in 'setSetting' | SetterKey]: ValueSetter;
    } & Pick<ProfileSettingsState, 'error' | 'isSaving'>
  >;
}
